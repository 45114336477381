import React from 'react';
import { Container } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import { FaqField } from 'components/Common/FAQField';
import SEO from 'components/Seo';
import { getTranslates } from 'helpers/translate.helper';
import { graphql } from 'gatsby';

export default ({ data }) => {
  const {
    i18n: { language }
  } = useTranslation();

  return (
    <div className="mt-30 mb-30">
      <SEO title={getTranslates(data.allStrapiLesLandToc, 'header', language)} />

      <Container>
        <Typography.Title level={2} className="clp-typography-title clp-typography-title_size_lg mb-30">
          {getTranslates(data.allStrapiLesLandToc, 'header', language)}
        </Typography.Title>

        <Typography.Text className="clp-typography-text clp-typography-text_size_lg mb-50">
          {getTranslates(data.allStrapiLesLandToc, 'desc', language)}
        </Typography.Text>

        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(i => {
          const title = getTranslates(data.allStrapiLesLandToc, `par${i}__header`, language);
          const description = getTranslates(data.allStrapiLesLandToc, `par${i}__content`, language);

          return <FaqField key={i} title={title} description={description} />;
        })}
      </Container>
    </div>
  );
};

export const query = graphql`
  query {
    allStrapiLesLandToc {
      edges {
        node {
          header__en
          header__fr
          desc__en
          desc__fr
          par1__header__en
          par2__header__en
          par3__header__en
          par4__header__en
          par5__header__en
          par1__header__fr
          par2__header__fr
          par3__header__fr
          par4__header__fr
          par5__header__fr
          par1__content__en
          par2__content__en
          par3__content__en
          par4__content__en
          par5__content__en
          par1__content__fr
          par2__content__fr
          par3__content__fr
          par4__content__fr
          par5__content__fr
          par6__header__en
          par6__header__fr
          par6__content__en
          par6__content__fr
          par7__header__en
          par7__header__fr
          par7__content__en
          par7__content__fr
          par8__header__en
          par8__header__fr
          par8__content__en
          par8__content__fr
          par9__header__en
          par9__header__fr
          par9__content__en
          par9__content__fr
          par10__header__en
          par10__header__fr
          par10__content__en
          par10__content__fr
          par11__header__en
          par11__header__fr
          par11__content__en
          par11__content__fr
          par12__header__en
          par12__header__fr
          par12__content__en
          par12__content__fr
          par13__header__en
          par13__header__fr
          par13__content__en
          par13__content__fr
          par14__header__en
          par14__header__fr
          par14__content__en
          par14__content__fr
        }
      }
    }
  }
`;
