import * as React from 'react';
import Typography from 'antd/es/typography';

interface IComponentProps {
  title: string;
  description?: string;
}

export const FaqField = ({ title, description }: IComponentProps) => {
  return (
    <>
      <Typography.Paragraph className="clp-typography-text clp-typography-text_size_md">
        <b>{title}</b>
      </Typography.Paragraph>

      <Typography.Paragraph className="clp-typography-text clp-typography-text_size_md">{description}</Typography.Paragraph>
    </>
  );
};
